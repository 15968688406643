import { useEffect, useState } from "react";

const MainPage = () => {

  const [months, setMonths] = useState();
  const [weeks, setWeeks] = useState();
  const [days, setDays] = useState();
  const [hours, setHours] = useState();
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();
  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    amountOfTime();
    const interval = setInterval(() => {
      amountOfTime();
      setTime(Date.now())
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const pad = (num, size) => {
    return ('000000000' + num).substr(-size);
  }

  const amountOfTime = () => {
    const date_future = new Date(2024, 10, 6);
    const date_now = new Date();

    var d = Math.abs(date_future - date_now) / 1000;                           // delta
    var r = {};                                                                // result
    var s = {                                                                  // structure
      // year: 31536000,
      month: 60 * 60 * 24 * 30,
      week: 60 * 60 * 24 * 7, // uncomment row to ignore
      day: 60 * 60 * 24,   // feel free to add your own row
      hour: 60 * 60,
      minute: 60,
      second: 1
    };

    Object.keys(s).forEach(function (key) {
      r[key] = Math.floor(d / s[key]);
      d -= r[key] * s[key];
    });

    setMonths(pad(r.month, 2));
    setWeeks(pad(r.week, 2));
    setDays(pad(r.day, 2));
    setHours(pad(r.hour, 2));
    setMinutes(pad(r.minute, 2));
    setSeconds(pad(r.second, 2));

  }

  return (
    <div className="homepage-bgimage center-container">
      <div style={{
        display: "flex", color: "white", textShadow: "0 0 1vw #000",
        flexDirection: "column", alignItems: "center", alignContent: "flex-end", fontFamily: "Quicksand"
      }}>
        <div style={{ fontSize: "8.5vh", letterSpacing: "0.4vw", textAlign: "center" }}>Welcome to Mom I Love You!</div>
        <div style={{ fontSize: "3vh", paddingTop: "5vh",width: "50vw", textAlign: "center" }}>The Corporation's primary mission seeks to promote the right to life for all human persons at all stages of development, to help normalize a culture of life across the United States, and to support mothers and families to help them choose life.</div>
        <div style={{ fontSize: "3vh", paddingTop: "5vh",width: "50vw", textAlign: "center" }}>Some activities include fundraising for pregnancy resource centers, presenting people with information on the beginning of life, and informing women in crisis preganancies of the free resources near them.</div>
        <div style={{ fontSize: "3vh", paddingTop: "5vh",width: "50vw", textAlign: "center" }}>Help us help these women, their partners, and their child flourish in whatever situation they are in, and surround them with love and expert care.</div>
        <div style={{ fontSize: "3vh", paddingTop: "5vh", textAlign: "center" }}>Contact Illarion Eremenko at illarion.eremenko@gmail.com with any questions.</div>
      </div>
    </div>
  )
}

export default MainPage;